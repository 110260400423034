<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Ingenieria Aplicada y Obras Civiles</h4>
            <h2>Representaciones<span></span></h2>
            
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>España</span>
                        <h3><a routerLink="/blog-details">Luminotecnia Internacional</a></h3>
                        <p>Contraladores de Luz y Potencia, Manejo de Aguas y Fuentes Monumentales, patentes de valvulas, control, automatizacion.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg2">
                        <span>España</span>
                        <h3><a routerLink="/blog-details">Grupo Ilimited</a></h3>
                        <p>Energías Alternativas, Construcción Especializada.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg3">
                        <span>Alemania</span>
                        <h3><a routerLink="/blog-details">Pi Integral Solution</a></h3>
                        <p>Diseño de Compuesto polimericos, equipamiento , partes y piezas electromecanicas, Energias Alternativas.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg4">
                        <span>China</span>
                        <h3><a routerLink="/blog-details">Neo Neo/CRled/MediaFade</a></h3>
                        <p>Ejecución de nuestros diseños en LED, fabrica de Led, luminarias Led: residencial, comercial, industrial, vialidad, tuneles, arqutectonica, decorativa, cortinas.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg4">
                        <span>Alemania</span>
                        <h3><a routerLink="/blog-details">Insta</a></h3>
                        <p>Ejecución de nuestros diseños en LED, fabrica de Led, luminarias Led: residencial, comercial, industrial, vialidad, tuneles, arqutectonica, decorativa, cortinas.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Alemania</span>
                        <h3><a routerLink="/blog-details">WIELAND ELECTRIC</a></h3>
                        <p>Fabricante de productos de Automatización y control.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Venezuela/Costa Rica</span>
                        <h3><a routerLink="/blog-details">ISOPanel</a></h3>
                        <p>Fabrica de Paneles Portantes Estructurales para despliegue modular.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Colombia</span>
                        <h3><a routerLink="/blog-details">TANTUX</a></h3>
                        <p>Sistema ConstructivoFormaletas Modulares para Vaciados Monoliticos.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>España</span>
                        <h3><a routerLink="/blog-details">Quercusolar</a></h3>
                        <p>Productos, Diseño,Procura y Despliegue en energias alternativas: solar, eolica,biodisgetores.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Venezuela/Perú/Mexico/USA</span>
                        <h3><a routerLink="/blog-details">Ekabel</a></h3>
                        <p>Fabrica de Cable tension baja a Alta.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Venezuela</span>
                        <h3><a routerLink="/blog-details">REcologica</a></h3>
                        <p>Fabrica de granulados de caucho reciclado para pisos, aplicaciones deportivas de alto nivel, decorativas, recreativas, industriales.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Republica Checa</span>
                        <h3><a routerLink="/blog-details">Preciosa</a></h3>
                        <p>Fabrica de Cristal de Bohemia y luminarias.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>España</span>
                        <h3><a routerLink="/blog-details">Uniarte</a></h3>
                        <p>Fabrica de Puertas masizas, comprimidas o compuestas, con nucleos de acero o corcho, hidrofugas, inifugas y o antirruido.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Usa/Inglaterra/China</span>
                        <h3><a routerLink="/blog-details">Enys Corp</a></h3>
                        <p>Integrador y Fabricante de Estructuras Metalicas industriales.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>España</span>
                        <h3><a routerLink="/blog-details">ADES</a></h3>
                        <p>Fabrica y Patentes Desalinizadoras y Tratamiento de Aguas.</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fa-solid fa-handshake-simple"></i></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>