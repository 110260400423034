<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/images/1925x625.avif" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content pt-5">
                    <div class="section-title pt-4">
                        <h4>Soluciones altamente creativas</h4>
                        <h3>Nuestra <span>Estrategia </span></h3>
                        <p  class="pt-3">La Dirección de Ingeniería de Software de Pafar ofrece desarrollos 
                            de aplicaciones nativas e híbridas, migración de sistemas y 
                            diseño de interfaces UI/UX que mejoran la experiencia del usuario. 
                            Desarrollamos soluciones multiplataforma y multiambiente, con énfasis en software libre y 
                            sistemas escalables en la nube, sobre bases de datos centralizadas y
                            descentralizadas. Utilizamos arquitecturas de microservicios para web, móvil y aplicaciones independientes, bajo metodologías Agile y Scrum, cumpliendo con los más altos estándares de codificación y calidad.</p>
                    </div>
                    <ul hidden class="features-list">
                        <li><i class="fa fa-check"></i>Multiplataforma</li>
                        <li><i class="fa fa-check"></i>Multiambiente</li>
                        <li><i class="fa fa-check"></i>Sobre diversos tipos
                            de lenguajes con énfasis en software libre</li>
                        <li><i class="fa fa-check"></i>Sobre bases de datos centralizas y descentralizadas</li>
                        <li><i class="fa fa-check"></i>Orientadas a Mcroservicios</li>
                        <li><i class="fa fa-check"></i>Ingeniería en la nube</li>
                        <li><i class="fa fa-check"></i>Web / Mobile/ Standalone</li>
                    </ul>
                    <p>En Ingeniería Aplicada y Construcción, Pafar realiza proyectos integrales en energía, 
                        ductería, diseño y construcción de infraestructura. 
                        Desde centros de procesamiento y sistemas de fibra óptica hasta plantas de tratamiento de agua y vialidad, 
                        diseñamos soluciones sostenibles que cumplen con normas internacionales, asegurando la eficiencia y 
                        el respeto por el medio ambiente.</p>

                </div>
                <div class="strategy-content pt-4">
                    <div class="section-title">
                        <h3>Nuestro <span>Compromiso </span></h3>
                        <p class="pt-3">Entregar resultados de calidad en tiempo y forma, 
                            agregando valor y manteniendo costos justos. 
                            Como sus aliados estratégicos, trabajamos para satisfacer sus necesidades, 
                            desarrollando soluciones innovadoras que promueven la sostenibilidad y 
                            mejoran la calidad de vida.</p>
                    </div>                   
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>