<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">

            <h2>Nuestras <span>Alianzas</span></h2>

        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/images/02logountelcom.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Untel</h3>
                            <a><span class="post">www.untel.com.tr</span></a>
                        </div>
                        <div class="social">
                            <ul>
                                <li>Fábrica Turca de cable especializado con más de 70 años de experiencia, en tensión
                                    media a baja y cable taylor made para los segtores: energia, industrial , marino,
                                    off shore, petróleo, minería, transporte y construcción. </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/images/04logoALUDERCOM.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Aluder</h3>
                            <a><span class="post">www.aluder.com</span></a>
                        </div>
                        <div class="social">
                            <ul>
                                <li>Fábrica Española de carpintería de Aluminio en soluciones de múltiples acabados y
                                    norma climali (estandart europero para cerramientos térmicos y acusticos),
                                    ventaneria, puertas, marcos de puertas y ventanas, mosquiteros invisibles, puertas
                                    de baño, cerramientos y fachadas de cristal.</li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/images/03logoT4RLCOM.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">T4RL</h3>
                            <a><span class="post">www.t4rl.com</span></a>
                        </div>
                        <div class="social">
                            <ul>
                                <li>Soluciones financieras seguras y certificadas a medida para impulsar los avances
                                    tecnológicos alrededor de criptomonedas, blockchain, NFTs, tokenizacion y metaverso,
                                    creando valor duradero y fondos a marcas, empresas y particulares tokenizando
                                    activos, previéndoles completa digitalización al arte, multimedia y películas.</li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/images/01logoT-DXCOM.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Taurus</h3>
                            <a><span class="post">www.t-dx.com</span></a>
                        </div>
                        <div class="social">
                            <ul>
                                <li>Compañía suiza. Primer Market Place regulado del Mundo, para el intercambió de
                                    activos privados y Tokenizacion segura. </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>