<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>¿Busca servicios exclusivos?</h4>
                <h2>Transforma tus ideas en realidad.</h2>
                <p>Desde aplicaciones innovadoras hasta proyectos de ingeniería y construcción de alta complejidad, Pafar transforma sus desafíos en soluciones potentes y efectivas.</p>
                <a (click)="onClick('contact')" class="btn btn-primary">Contáctenos</a>
                <a hidden (click)="onClick('work')" class="btn btn-primary view-work">Portafolio</a>
            </div>
        </div>
    </div>
</div>