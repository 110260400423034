<div class="subscribe-area ptb-100">
    <div class="container">
        <div class="newsletter">
            <h2>Suscríbete a nuestro boletín
            </h2>
            <p>Mantente al día con nuestras últimas novedades.</p>
            <form class="newsletter-form" #suscribeForm="ngForm" (ngSubmit)="suscribe(suscribeForm.value)">
                <input  required type="email" name="email" email #email="ngModel" ngModel class="form-control" placeholder="Indica tu email aquí">
                <div class="alert alert-danger" *ngIf="email.touched && email.errors">
                    <div *ngIf="email.errors.required">Email es requerido.</div>
                    <div *ngIf="email.errors.email">Email no es válido.</div>
                </div>
                <button class="btn btn-primary" [class.disabled]="!suscribeForm.valid" type="submit">Subscribir</button>
            </form>
        </div>
    </div>
</div>