<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sobre <span>Pafar</span></h2> <!-- AZUL-->
            <p>Ingenieria y Construcción.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Creamos <span>Soluciones</span></h2>
                        <p>Somos una empresa dedicada a desarrollos y proyectos en los sectores: </p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Ingeniería de Software</li>
                        <li><i class="fas fa-check"></i>Ingeniería Aplicada</li>
                        <li><i class="fas fa-check"></i>Construcción</li>
                        <li><i class="fas fa-check"></i>Energías Alternativas</li>
                        <li><i class="fas fa-check"></i>Tratamientos de Agua</li>
                        <li><i class="fas fa-check"></i>Iluminación Arquitectura</li>
                        <li><i class="fas fa-check"></i>Urbanismo</li>
                        <li><i class="fas fa-check"></i>Deporte</li>
                        <li><i class="fas fa-check"></i>Ambientes para Seguridad Electrónica y Centros de Procesamiento
                            de Datos</li>
                        <li><i class="fas fa-check"></i>Urbanismo</li>
                    </ul>

                    <p>Para nosotros la tecnología, innovación, el capital intelectual y humano es fundamental para brindar soluciones innovadoras y con calidad.
                        Para ello, disponemos de personal especializado totalmente comprometido, brindando innovación y excelencia.</p> 
                    <p>Contamos con experiencia y capacidad demostrada en proyectos gubernamentales,
                        corporativos, desarrollos comerciales, industriales, bancarios y transaccionales.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <!--<div class="about-video">
                    <img src="assets/images/screenshot_v1_pafar.png" alt="about">
                    <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i
                                class="fas fa-play"></i></button>
                    </div>
                </div>-->
                <div class="about-video" *ngIf="!isVideoPlaying">
                    <img src="assets/images/screenshot_v1_pafar.png" alt="about">
                    <div class="video-btn">
                        <button class="popup-youtube" (click)="playVideo()">
                            <i class="fas fa-play"></i>
                        </button>
                    </div>
                </div>
                <div class="video-container" *ngIf="isVideoPlaying" style="position: relative;">
                    <button class="close-video-btn" (click)="closeVideo()">
                        <i class="fas fa-times"></i>
                    </button>
                    <iframe 
                        [src]="videoUrl" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/zRhyHOAcF5o"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </ngx-smart-modal>
</div>