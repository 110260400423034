import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { MainService } from '../../../services/main.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    subjectList: Array<any>;
    mysubject: any;

    constructor(private viewportScroller: ViewportScroller, 
        private mainService: MainService,
        private toastr: ToastrService) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    async ngOnInit() {
        this.subjectList = await this.mainService.getSubjectList();
        console.log('resp >>>>', this.subjectList);
    }

    submit(form: NgForm){
        const values = form.value;
        var name = values.name;
        console.log(name);
        
        var email = values.email;
        console.log(email);

        var number = values.number;
        console.log(number);
        
        var subject = values.subject;
        console.log(subject);
        
        var message = values.message;
        console.log(this.mysubject);


			this.mainService.postContact({
				"nombre": name,
				"email": email,
				"asunto":this.mysubject.name,
				"telefono": number,
				"mensaje": message,
			}).subscribe((res:any)=>{
				form.resetForm();
				this.toastr.success('Su mensaje fué enviado con éxito. En la brevedad posible será contactado.')
			}, (err)=>{
				this.toastr.error('Su mensaje no fué enviado. Intentelo más tarde.')
			});

    }

}