import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    @ViewChild('navpafar') navpafar: ElementRef<HTMLDivElement>;

    displayLogo:boolean = false;


    constructor(private viewportScroller: ViewportScroller) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }


    @HostListener('window:scroll', ['$event'])
    changeLogo(event:any): void {       
        if(window.scrollY > 0){
            this.displayLogo = true;
        }else{
            this.displayLogo = false;
        }
    }

    

}