<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestra <span>Visión, Misión y Valores</span></h2>

        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa fa-eye"></i>
                    <h3>Visión</h3>
                    <p>Ser, en el año 2034, una empresa vanguardista e innovadora, en el desarrollo y materialización de
                        soluciones que aporten a la infraestructura tecnológica y/o física, con penetración en productos
                        y servicios especializados en Latino América y el continente Europeo.</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-bullseye"></i>
                    <h3>Misión</h3>
                    <p>Prestar servicios afines a la ingeniería de software, ingeniería aplicada y construcción bajo
                        criterios ecológicos, orientados al bienestar social, el mejoramiento de la calidad de vida y el
                        desarrollo sustentable, con honestidad, equidad y profesionalismo, a través de las capacidades
                        de nuestro talento humano, procesos innovadores y el uso asertivo de la tecnología.</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-regular fa-heart"></i>
                    <h3>Valores</h3>
                        <ul class="features-list">
                            <li><i class="fa fa-check"></i>Honestidad</li>
                            <li><i class="fa fa-check"></i>Transparencia</li>
                            <li><i class="fa fa-check"></i>Profesionalismo</li>
                            <li><i class="fa fa-check"></i>Ética</li>
                            <li><i class="fa fa-check"></i>Dedicación</li>
                        </ul>                        
                    <span>3</span>
                </div>
            </div>
        </div>
    </div>
</div>