<div class="main-banner item-bg-one">
    <particles [params]="myParams" [style]="myStyle" [width]="width" [height]="height"></particles>
    <div class="creative-banner-three"></div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>Trabajamos</h4>
                    <h1>Creando <span>Soluciones</span></h1>
                    <p>Innovamos ideas para un mundo mejor.</p>

                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<!--<app-funfacts></app-funfacts>-->

<app-team></app-team>

<app-how-we-work hidden></app-how-we-work>

<app-services></app-services>

<app-why-we-different hidden></app-why-we-different>

<!--<app-work></app-work>-->

<!--<app-pricing></app-pricing>-->

<!--<app-faq></app-faq>-->

<app-feedback hidden></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>