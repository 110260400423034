<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>Pafar</span></a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
        <p>{{'Copyright @2022 All Rights Reserved.'}}</p>
        <p>Hecho a mano con <i class="fa-regular fa-heart ms-1"></i></p>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>