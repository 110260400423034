import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller, 
    private toastr: ToastrService) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }


  suscribe(form){
    if(form.email){
      setTimeout(() => {
        this.toastr.success(`¡Gracias por suscribirte a nuestros boletines!
          Pronto recibirás nuestras novedades, noticias y contenido exclusivo directamente en tu correo.`);
      }, 300);
    }else{
      this.toastr.warning(`Indica tu email para suscribirte!!`)
    }

  }

}
