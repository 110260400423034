<div id="services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros <span>Productos y Servicios</span></h2>
            <p>Una solución para cada necesidad</p>
        </div>
        <div class="row justify-content-center">
            <div class="section-subtitle ms-2 mb-2">
                <h4>Ingeniería de Software</h4>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/1ArquitecturadiseñodesarrollofullstackAplicaciones.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Arquitectura, diseño y desarrollo full stack de Aplicaciones</h3>
                        <p>Con énfasis en soluciones corporativas, gaming, mundo deportivo, transaccionalidad, banca,
                            finanzas y activos digitales (Blockchain / Fiat / Tokenizacion).</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/2SistematizaciónIntegraciónProcesosNegocios.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="fab fa-linode"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Sistematización e Integración de Procesos de Negocios</h3>
                        <p>En aplicativos multi entornos y según necesidad Cliente Servidor / Web / Mobile, con énfasis
                            en servicios y micro servicios e ingeniería en la nube.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/3DiseñoDesarrolloimplantaciónSistemas.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="fas fa-desktop"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Diseño y Desarrollo e implantación de Sistemas</h3>
                        <p>Utilizando lenguajes de programación vanguardistas (entornos libres o propietarios), full
                            stack, multi
                            canales, multi plataformas, según el sector, enfoque y requerimiento.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/4DiseñoDesarrolloAppnativashíbridas.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Diseño y Desarrollo de App nativas e híbridas</h3>
                        <p>Para sistemas operativos Android o IOS logrando así adaptarse a los requisitos del cliente y
                            sus posibilidades tanto
                            técnicas como monetarias.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/5Migraciónsistemas.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="fas fa-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Migración de sistemas</h3>
                        <p>A entornos y lenguajes distintos a los que fueron concebidos.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/6UiUxDesign.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Ui & Ux Design</h3>
                        <p>Creamos experiencias de usuario e interfaces que invitan a repetir experiencia y
                            facilitan el uso de sitios web y aplicaciones móviles.</p>
                    </div>
                </div>
            </div>

        </div>
        <div class="row justify-content-center">
            <div class="section-subtitle ms-2 mb-2">
                <h4>Ingeniería Aplicada y Construcción</h4>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/6Gestiónproyectos.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Gestión de proyectos</h3>
                        <p>De construcción e Inspección de Obras</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/7ElaboraciónProyectosIngenieríaAplicada.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="fas fa-camera-retro"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Elaboración de Proyectos de Ingeniería Aplicada,</h3>
                        <p>Arquitectura y todos aquellos relacionados a Obras Civiles Construcción de Estructuras en
                            Acero y Concreto.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/8Energía.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Energía</h3>
                        <p>Con énfasis en solares y eólicas,  Potencia y Sistemas de Puesta a Tierra.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/9Ducteria.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Ducteria,</h3>
                        <p>Climatización de Precisión y Estándar, Chillers y Calderas.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/10DiseñoProducciónconstrucciónfuentesrobotizadas.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Diseño, Producción, construcción</h3>
                        <p>E implantación de Fuentes Robotizadas cromo Acuáticas.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/11CentrosProcesamientoDeDatosDomótica.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Centros de Procesamiento </h3>
                        <p>De Datos y Domótica.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/12FibraÓptica.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Fibra Óptica,</h3>
                        <p>Cableado Estructurado Lan y WAN, enlaces Inalámbricos, Wifi.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/13GasesMedicinales.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Gases Medicinales</h3>
                        <p></p>
                    </div>
                </div>
            </div>      
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/14UrbanismoMovimientosTierraVialidad.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Urbanismo, Movimientos de Tierra, Vialidad</h3>
                        <p></p>
                    </div>
                </div>
            </div> 
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/15DiseñoFabricaciónVentalucesLED.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Diseño, Fabricación y Venta</h3>
                        <p>De Iluminación LED en los sectores: Industrial, Vialidad, Túneles, Arquitectura, Comercial, Residencial.</p>
                    </div>
                </div>
            </div>  
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/16CableadotensiónmediaBaja.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Diseño, Producción y Procura </h3>
                        <p>de Cableado de tensión media a Baja.</p>
                    </div>
                </div>
            </div> 
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/17DesalinizadorasDepuradorasAgua.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Desalinizadoras y Depuradoras de Agua</h3>
                        <p></p>
                    </div>
                </div>
            </div> 
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/18Proyectosdeportivosafines.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Proyectos deportivos y afines</h3>
                        <p>Con certificación internacional (gimnasios, centros de futbol, canchas de padel).</p>
                    </div>
                </div>
            </div> 
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/images/19ProcuraMateriales.png" alt="services-img">
                        <div class="icon" hidden>
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Procura de Materiales</h3>
                        <p> Para la ingeniería amplia y  la industria Minera, Petrolera, Industrial obras Civiles.</p>
                    </div>
                </div>
            </div>     
        </div>
    </div>
</div>