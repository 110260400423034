<div class="skill-area ptb-100 bg-fffdfd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="skill-content">
                    <div class="section-title">
                        <h2>Algunos de nuestros <span>Skills</span></h2>
                        <p>La excelencia y la innovación tecnolófica son nuestra base. Estos son algunos de los conocimientos clave que utilizamos para llevar sus proyectos al siguiente nivel:</p>
                    </div>
                    <div class="skills-list">
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Desarrollo de Software</h4>
                                <div class="skill-percentage">
                                    <div class="count-box"><span class="count-text">95</span>%</div>
                                </div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="95"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Diseño UI/UX</h4>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text">90</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="90"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Gestión de Proyectos</h4>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text">92</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="92"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Ingeniería Aplicada</h4>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text">97</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="97"></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="skill-video" *ngIf="!isVideoPlaying">
                    <img src="assets/images/screenshot_v2_pafar.png" alt="about">
                    <div class="video-btn">
                        <button class="popup-youtube" (click)="playVideo()">
                            <i class="fas fa-play"></i>
                        </button>
                    </div>
                </div>
                <div class="video-container" *ngIf="isVideoPlaying" style="position: relative;">
                    <button class="close-video-btn" (click)="closeVideo()">
                        <i class="fas fa-times"></i>
                    </button>
                    <iframe 
                        [src]="videoUrl" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupTwo [identifier]="'popupTwo'">
        <iframe src="https://www.youtube.com/embed/tAB1vKP6tCY" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>