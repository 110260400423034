<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2><span>Contáctenos</span></h2>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Nombre y Apellido</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && name.errors">
                                        <div *ngIf="name.errors.required">Nombre y Apellido es requerido.</div>
                                        <div *ngIf="name.errors.minlength">Nombre debe tener mínimo {{ name.errors.minlength.requiredLength }} caracteres.</div>
                                        <div *ngIf="name.errors.maxlength">Nombre debe tener máximo 50 caracteres.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="email" email #email="ngModel" class="form-control" id="email">
                                    
                                    <div class="alert alert-danger" *ngIf="email.touched && email.errors">
                                        <div *ngIf="email.errors.required">Email es requerido.</div>
                                        <div *ngIf="email.errors.email">Email no es válido.</div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class=" mb-3">
                                    <label>Asunto</label>
                                    <ng-select required [items]="subjectList" 
                                    bindLabel="name"
                                    [(ngModel)]="mysubject" name="subject" #subject="ngModel" id="subject">
                                    </ng-select>
                                   <!-- <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject"> -->
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Asuntois es requerido.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Teléfono</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Teléfono es requerido.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Mensaje</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Mensaje es requerido.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Enviar Mensaje</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i> <span>Ubicación:</span>
                                    <p>
                                        Torre Norte,  <br>
                                        piso 3, Ofci 3-7/3-8, <br>
                                        Centro Comercial El Recreo, <br>
                                        Calle el Recreo, <br>
                                        Caracas, Venezuela
                                    </p>
                                
                                </li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:hello@xavro.com"><span>Email:</span>{{'soporte@pafar.net'}}</a></li>
                                <li hidden><i class="fas fa-phone-alt"></i> <a href="tel:1244122445515"><span>Teléfono:</span>(+58)999-9999999</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>