<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">

            <h2>Somos <span>creativos</span></h2>
            <p>Edificando nuevas realidades, físicas y virtuales, donde la originalidad  y la sostenibilidad se encuentran para transformar nuestro mundo.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <!--<i class="far fa-edit"></i>-->
                        <img src="assets/images/demo/iconos_Innovación_tecnológica.png">
                    </div>
                    <h3>Innovación tecnológica</h3>
                    <p>Aplicamos tecnologías avanzadas para optimizar procesos y ofrecer soluciones eficientes que se adaptan al futuro.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <!--<i class="fas fa-laptop"></i>-->
                        <img src="assets/images/demo/icono_sustentabilidad.png">
                    </div>
                    <h3>Compromiso y sostenibilidad</h3>
                    <p>Nos dedicamos a crear un impacto positivo, promoviendo prácticas responsables y sostenibles en cada proyecto.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <!--<i class="far fa-life-ring"></i>-->
                        <img src="assets/images/demo/iconos_Atención_personalizada.png">
                    </div>
                    <h3>Atención y orientación al cliente</h3>
                    <p>Ofrecemos un servicio personalizado, asegurando que cada cliente reciba el apoyo y guía necesarios para sus necesidades.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>