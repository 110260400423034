import { Injectable, Inject, PLATFORM_ID} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { isPlatformBrowser } from '@angular/common';

import { DOCUMENT } from '@angular/common';
import * as qs from 'qs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class MainService {

    public baserUrl: string = environment.baseUrl;

    constructor(private http: HttpClient){}
   

    // ======================= //
    // Post Contact Us
    // ======================= //
    postContact(body:any){
        return this.http.post(`${this.baserUrl}/account/contactame`,body);
    }

    /**
     * get subject list for contact
     * @returns 
     */
    async getSubjectList():Promise<Array<any>>{
        return await this.http.get(`${this.baserUrl}/account/correosubject/list`).toPromise().then((resp:any)=>{

            const list = resp.data.map((item:any)=>{
                return {id: item.id, name:item.nombresubject}
            })
            return list;
        }).catch(()=>{
            return null;
        });

    }

   
}