<div id="work" class="work-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Nuestras <span>Sedes</span></h2>            
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="All">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img5.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Brand">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Design">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Graphic">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Photoshop">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Illustrator">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img5.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>